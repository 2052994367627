import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useQueryParam, StringParam } from "use-query-params"
import { navigate } from "gatsby"
import axios from "axios"

import Layout from "../../components/Layout"
import ReviewScore from "../../components/ReviewInputScore"

import * as styles from "./styles.module.scss"

const Review = () => {
  useEffect(() => {
    const paramstr = document.location.search
    document.location.href = "https://nell.life/review/form/" + paramstr
  }, [])

  return <></>

  // const [name, setName] = useState("")
  // const [score, setScore] = useState(0)
  // const [title, setTitle] = useState("")
  // const [context, setContext] = useState("")

  // const [orderNumber] = useQueryParam("order_number", StringParam)

  // const showErrorDialog = errorMessages => {
  //   window.alert(errorMessages.join("\n"))
  // }

  // const handleSubmit = e => {
  //   e.preventDefault()

  //   const data = {
  //     order_number: orderNumber,
  //     name: name,
  //     score: score,
  //     title: title,
  //     text: context,
  //   }

  //   let errorMessages = []

  //   if (!data.name) {
  //     errorMessages.push("・お名前が入力されていません")
  //   }

  //   if (!data.score || data.score === 0) {
  //     errorMessages.push(
  //       "・評価が入力されていません\n星のマークを押して1~5の評価をお願いします"
  //     )
  //   }

  //   if (!data.title) {
  //     errorMessages.push("・タイトルが入力されていません")
  //   }

  //   if (!data.text) {
  //     errorMessages.push("・レビュー内容が入力されていません")
  //   }

  //   if (errorMessages.length === 0) {
  //     if (window.confirm("ご記入された内容にお間違いありませんか？")) {
  //       postData(data)
  //     }
  //   } else {
  //     showErrorDialog(errorMessages)
  //   }
  // }

  // const postData = data => {
  //   axios
  //     .post("https://api.nell.life/v1/review/", data)
  //     .then(res => {
  //       navigate("/review/thanks")
  //     })
  //     .catch(err => {
  //       switch (err.response.data.code) {
  //         case 3004:
  //           showErrorDialog(["存在しない注文番号です"])
  //           break
  //         case 6000:
  //           showErrorDialog(["必要なパラメータが不足しています"])
  //           break
  //         default:
  //           showErrorDialog(["エラーが発生しました"])
  //           break
  //       }
  //     })
  // }

  // useEffect(() => {
  //   if (!orderNumber) {
  //     window.location.href = "https://nell.life"
  //   }
  // }, [orderNumber])

  // return (
  //   <Layout>
  //     <Helmet>
  //       <title>NELLレビューフォーム</title>
  //     </Helmet>
  //     <div className={styles.review}>
  //       <div className={styles.message}>「NELL」レビュー記入フォーム</div>
  //       <form className={styles.form} onSubmit={handleSubmit}>
  //         <div className={styles.form_group}>
  //           <label>
  //             <div className={styles.item_label}>お名前(ニックネーム)</div>
  //             <input
  //               className={styles.text_input}
  //               type="text"
  //               value={name}
  //               onChange={e => setName(e.target.value)}
  //             />
  //           </label>
  //         </div>
  //         <div className={styles.form_group}>
  //           <div className={styles.item_label}>評価</div>
  //           <ReviewScore score={score} select={value => setScore(value)} />
  //         </div>
  //         <div className={styles.form_group}>
  //           <label>
  //             <div className={styles.item_label}>レビュータイトル</div>
  //             <input
  //               className={styles.text_input}
  //               type="text"
  //               value={title}
  //               onChange={e => setTitle(e.target.value)}
  //             />
  //           </label>
  //         </div>
  //         <div className={styles.form_group}>
  //           <label>
  //             <div className={styles.item_label}>レビュー</div>
  //             <textarea
  //               className={styles.text_input}
  //               value={context}
  //               onChange={e => setContext(e.target.value)}
  //             />
  //           </label>
  //         </div>
  //         <div className={styles.button_container}>
  //           <button className={styles.submit_button} type="submit">
  //             投稿する
  //           </button>
  //         </div>
  //       </form>
  //     </div>
  //   </Layout>
  // )
}

export default Review
